<template>
  <v-layout>
    <div v-if="noData" class="hip--fullscreen">
      <v-progress-circular :size="50" indeterminate color="primary" />
    </div>
    <div v-else id="pageContainer" />
  </v-layout>
</template>

<script>
import { hipUrl } from '../config/config';
import * as pdfjsLib from 'pdfjs-dist';

export default {
  name: 'Hip',
  components: {},
  data() {
    return {
      noData: true,
      data: '',
      showIframe: false,
    };
  },

  async created() {
    await this.getData();
    this.noData = false;
  },
  methods: {
    async getData() {
      const code = this.$route?.params?.code;
      if (!code) return;

      const onlyPrint = this.$route?.params?.pathMatch === 'print';

      console.log(`${hipUrl}/${code}`);

      try {
        const { data } =
          (await this.$http({
            method: 'get',
            url: `${hipUrl}/${code}`,
            responseType: 'arraybuffer',
          })) || {};

        if (!data) return;

        console.log(`${hipUrl}/${code}`);
        console.log(data);

        await this.showFileDownload(data, code, onlyPrint);
      } catch (e) {
        this.$toast.error(this.$t('hiring.apiError'));
      }
    },
    async showFileDownload(data, code, onlyPrint) {
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      if (!onlyPrint) {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.setAttribute('download', `${this.$t('recipe.recipe')}_${code}.pdf`);
        link.click();
      }

      pdfjsLib.GlobalWorkerOptions.workerSrc = await import('pdfjs-dist/build/pdf.worker.entry');
      const loadingTask = pdfjsLib.getDocument({ data: data });

      const outputScale = window.devicePixelRatio || 1;

      loadingTask.promise.then(pdf => {
        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
          pdf.getPage(pageNumber).then(function(page) {
            const desiredWidth = document.getElementById('pageContainer').clientWidth;
            const viewport = page.getViewport({ scale: 1 });

            const scale = desiredWidth / viewport.width;
            const scaledViewport = page.getViewport({ scale: scale });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            document.getElementById('pageContainer').appendChild(canvas);
            document.getElementById('pageContainer').appendChild(document.createElement('br'));

            canvas.height = scaledViewport.height * outputScale;
            canvas.width = scaledViewport.width * outputScale;
            canvas.style.width = Math.floor(scaledViewport.width) + 'px';
            canvas.style.height = Math.floor(scaledViewport.height) + 'px';

            const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

            const renderContext = {
              canvasContext: context,
              transform: transform,
              viewport: scaledViewport,
            };

            page.render(renderContext);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hip--fullscreen {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

#pageContainer {
  margin: auto;
  width: 80%;
}

@media only screen and (max-width: 600px) {
  #pageContainer {
    margin: auto;
    width: 100%;
  }
}

#pageContainer canvas {
  display: inline-block;
  width: 100%;
}

div.page {
  display: inline-block;
}

canvas {
  width: 100%;
  height: 100%;
}
</style>
